.home-section-category-main-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.home-section-category {
    /* border: 1px solid red; */
}

.home-section-category-text {}

.home-section-category-text h2 {
    font-size: 40px;
    font-family: var(---font01);
}

.home-section-category-text h2 span {
    font-family: var(---font01);
    color: var(---colorG);
}

.home-section-category-text p {
    font-family: var(---font01);

}

.home-section-category-section-card-con {
    /* border: 1px solid blue; */
    padding: 30px 0px;
}

.home-section-category-section {
    border: 1px solid #ebebeb;
    padding: 20px 10px 10px 10px;
    margin-top: 30px;
}

.home-section-category-image-con {
    /* border: 1px solid green; */
    margin: 0px 0px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section-category-image {
    /* border: 1px solid red; */
    height: 10vh;
    width: 10vh;
    border: 1px solid #ebebeb;
    padding: 5px;
}

.home-section-category-image img {
    height: 100%;
    width: 100%;
}

.home-section-category-section h3 {
    font-family: var(---font01);
    font-size: 17px;
    height: 8vh;
}

.home-section-category-section h4 {
    font-family: var(---font01);
}