.ProfileEdit-con-inputs {
    text-align: left;
}

.ProfileEdit-con-inputs {}

/* //////////////////////////////////////////////////////////////////////////////// */

.ProfileEdit {}

.ProfileEdit-con {}

.ProfileEdit-con-heading {
    margin: 0px 0px 20px 0px;
}

.ProfileEdit-con-heading h2 {
    text-align: left;
    font-size: 30px;
    font-family: var(---font02);
}


.Profile-Section-Sidebar .nav-pills button svg {
    /* border: 1px solid red; */
    font-size: 20px;
    margin: 0px 10px 0px 0px;
}

.Profile-Section-Tabs {
    box-shadow: 0 30px 50px #052f281f;
    padding: 30px;
}

.Profile-Section-Tabs .form-group label {
    font-family: var(---font01);
    margin: 0px 0px 6px 3px;
}

.ProfileEdit-con-inputs-con-mail {
    margin: 0px 0px 0px 0px;
}

.ProfileEdit-btn {
    /* border: 1px solid blue; */
    margin: 30px 0px 0px 0px;
}

.ProfileEdit-btn button {
    width: 60%;
    border: none;
    padding: 10px 0px;
    color: white;
    background-color: black;
    border: 2px solid black;
}

.ProfileEdit-btn:hover button {
    background-color: white;
    color: black;
    border: 2px solid black;
}

.ProfileEdit .ant-modal-footer {
    display: none !important;
}

@media(max-width:992px) {
    .Profile-Section-Tabs {
        box-shadow: 0 30px 50px #052f281f;
        padding: 30px;
        margin-top: 40px;
    }
}