.profile-reviews-section{
    /* border: 1px solid blue; */
}

.profile-reviews-section-main-con{
    /* border: 1px solid red; */
}

.profile-reviews-head-con{
    margin: 0px 0px 20px 0px;
}

.profile-reviews-head-con h2{
    text-align: left;
    font-size: 30px;
    font-family: var(---font02);
}