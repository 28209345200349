.home-faq-section{
    padding: 5vh 0px;
}

.home-faq-head{
    text-align: left;
    padding: 20px 0px;
    font-family: var(---font01);
}

.accordion-body{
    text-align: left;
}