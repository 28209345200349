.home-section-process-main-con{
    /* border: 1px solid red; */
    background-color: whitesmoke;
    padding: 10vh 0px;
}

.home-section-head-text{
    /* border: 1px solid blue; */
}

.home-section-head-text h2{
    font-size: 40px;
    font-family: var(---font01);
}
.home-section-head-text h2 span{
    color: var(---colorG);

}

.home-section-head-text p{
    font-family: var(---font01);
}

.home-section-process-con{
    /* border: 1px solid blue; */
    padding: 40px 0px;
}

.home-section-process-card-con{
    /* border: 1px solid red; */
    padding: 20px 20px;
}

.home-section-process-card-number{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.home-section-process-card-number h2{
    margin: 0px;
    padding: 15px 23px;
    border-radius: 50px;
    font-family: var(---font01);
    background: var(---colorG);
    color: white;
    font-size: 20px;
}

.home-section-process-card-con h3{
    font-family: var(---font01);
}

.home-section-process-card-con p{
    font-family: var(---font01);
}