.home-section-3 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.home-section-3-con {
    /* border: 1px solid blue; */
}

.home-section-3-heading-con {
    /* border: 1px solid blue; */
    margin-bottom: 50px;
}

.home-section-3-heading-con h2 {
    font-family: var(---font01);
    font-size: 50px;
}

.home-section-3-heading-con h2 span {
    color: var(---colorG);
}

.home-section-3-heading-con p {
    font-family: var(---font01);
}

.stock-card-main-con-card-con a{
    text-decoration: none;
}

/* ////////////////////////////////////////// */


.home-section-3-Card-main-con {
    /* border: 1px solid blue; */
}


/* card  */


.stock-card-main-con {
    border: 1px solid #0000001c;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.stock-card-main-con-card-con a{
    text-decoration: none;
    color: black;
}

.stock-card-main-con:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    transition: 0.5s ease;
}

.stock-card-image-con {
    /* border: 2px solid blue; */
    height: 20vh;
    min-width: 20vh;
    max-width: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stock-card-image-con img {
    height: auto;
    max-height: 20vh;
    max-width: 100%;
    width:auto;
}

.stock-card-details-con {
    /* border: 1px solid green; */
    margin: 0px 0px 0px 15px;
}

.stock-card-details-con h3 {
    font-family: var(---font01);
    font-size: 18px;
    text-align: left;
}

.stock-card-details-con h4 {
    text-align: left;
    font-family: var(---font01);
    font-size: 18px;
}

.stock-card-details-info-con {
    display: flex;
    align-items: center;
}

.stock-card-details-info-con h5 {
    font-weight: 400;
    font-size: 13px;
    font-family: var(---font01);
    margin: 0px 8px 0px 0px;
}

.stock-card-details-info-con h6 {
    margin: 0px;
}

.Home-section-3-stocks-btn-con{
    /* border: 1px solid red; */
}

.Home-section-3-stocks-btn-con button{
    border: none;
    padding: 13px 40px;
    font-family: var(---font01);
    font-size: 13px;
    background: var(---colorG);
    color: white;
    border-radius: 5px;
}

/* /// */

.home-section-3-filter-con-main{
    /* border: 1px solid blue; */
    margin-bottom: 20px;
}

.home-section-3-filter-con{
    text-align: left;
}

.home-section-3-filter-portfolio-con{
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    width: max-content;
    padding: 4px 20px;
    border-radius: 50px;
    background-color: #DAA520;
}

.home-section-3-filter-portfolio-image-con{
    /* border: 1px solid green; */
    height: 20px;
    width: 20px;
    display: flex;
}
.home-section-3-filter-portfolio-image-con img{
    height: 100%;
    width: 100%;
}
.home-section-3-filter-portfolio-con h3{
    font-family: var(---font01);
    font-size: 12px;
    margin: 0px 0px 0px 10px;
    color: white;
}