.stock-details-section-1 {
    /* border: 1px solid red; */
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: whitesmoke;
}

.details-banner {
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Images/Banner/details\ page.jpg") !important;
    background-size: cover !important;
    background-position: center !important;
}


.stock-details-banner {
    display: flex;
    align-items: center;
}


.stock-details-banner h2 {
    font-family: var(---font01);
    font-size: 13px;
    margin: 0px;
}

.stock-details-banner h3 {
    font-family: var(---font01);
    margin: 0px 10px;
    font-size: 13px;
}

/* section 2  */

.stock-details-section-2 {
    /* border: 1px solid blue; */
    padding: 30px 30px;
}

.stock-details-section-2-con {
    /* border: 1px solid red; */
}

.stock-details-section-2-right-side-main-con {
    /* border: 1px solid blue; */
}

.stock-details-2-right-side-form {
    border-radius: .625rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
}

.stock-details-2-right-side-form-title {
    /* border: 1px solid red; */
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
    padding: 15px;
    position: sticky;
    top: 0;
}

.stocks-section-main-con a {
    text-decoration: none;
    color: black;
}

.stock-details-2-right-side-form-title h3 {
    font-family: var(---font01);
    font-size: 17px;
}

.stock-details-2-right-side-form-title h4 {
    font-family: var(---font01);
    font-size: 17px;
    font-weight: 300;
}

.stock-details-2-right-side-form-price {
    padding: 15px;
}

/* //////////////////////// details con /////////////////////////// */

.stock-details-section-2-details-main-con-Info {
    /* border: 1px solid blue; */
}

.stock-details-section-2-details-image-name-con {
    /* border: 1px solid green; */
}

.stock-details-section-2-details-image-name-con h2 {
    text-align: left;
    font-family: var(---font01);
    font-size: 18px;
    margin: 10px 0px;
}

.stock-details-section-2-details-image {
    /* border: 1px solid red; */
    height: 20vh;
    min-width: 20vh;
    max-width: 20vh;
}

.stock-details-section-2-details-image img {
    height: auto;
    max-height: 20vh;
    max-width: 100%;
    width: auto;
}

.stock-details-section-2-details-price {
    display: flex;
    align-items: center;
    font-family: var(---font01);
}

.stock-details-section-2-details-price h3 {
    font-size: 22px;
    margin: 0px;
}

.stock-details-section-2-details-price h4 {
    font-size: 14px;
    font-weight: 400;
    color: var(---colorG);
    margin: 0px 5px;
}

.stock-details-section-2-details-price h5 {
    font-size: 12px;
    margin: 0px;
}

.stocks-details-section-2-main-con {
    /* border: 2px solid blue; */
    text-align: left;
    padding: 20px 0px;
    border-top: 1px solid #80808036;
    margin-top: 20px;
}

.stocks-details-section-2-con {}

.stocks-details-section-2-con h2 {
    font-family: var(---font01);
    font-size: 20px;
}

.stocks-details-section-2-con h3 {
    font-family: var(---font01);
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 10px;
}

.stocks-details-section-2-con h3 span {
    font-weight: 500;
}

/* /////////////////////////////////// */

.stocks-details-section-2-fundamental {
    text-align: left;
}

.stocks-details-section-2-fundamental h2 {
    margin-bottom: 22px;
    font-family: var(---font01);
}

.stocks-details-section-2-fundamental-details {
    padding: 15px;
    border: 1px solid #80808036;
}

.stocks-details-section-2-fundamental-points {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 35px 0px 0px;
}

.stocks-details-section-2-fundamental-points h3 {
    font-family: var(---font01);
    font-size: 15px;
    padding: 0px 30px 0px 0px;
    font-weight: 300;
}

.stocks-details-section-2-fundamental-points h4 {
    font-family: var(---font01);
    font-size: 15px;
    font-weight: 600;
}

.stocks-details-section-2-fundamental-points h4 span {
    font-weight: 400;
}

/* ///////////////////////////// FaQ //////////////////////////// */

.stock-details-faq-section-con-main {
    /* border: 1px solid blue; */
}

.stock-details-faq-section-con {
    /* border: 1px solid red; */
}

.stock-details-faq-section-heading {}

.stock-details-faq-section-heading h2 {
    font-family: var(---font01);
    font-size: 40px;
}

.stock-details-faq-section-heading h2 span {
    color: var(---colorG);
}

.stock-details-faq-section-heading p {
    font-family: var(---font01);
}

.stock-details-faq-section-drop {
    /* border: 1px solid blue; */
    padding: 20px 30px;
}

.stocks-details-section-2-btn-con {
    /* border: 2px solid blue; */
    margin-top: 30px;

}

.stocks-details-section-2-btn-con button {
    width: 50vh;
    /* border: 1px solid gray; */
    margin: 0px 30px 0px 30px;
    font-family: var(---font01);
    text-transform: uppercase;
    padding: 7px 0px;
    border: none;
    border-radius: 5px;
}


.buy-btn {
    background-color: green;
    color: white;
}

.sell-btn {
    background-color: red;
    color: white;
}

.stock-details-buy-btn {
    background-color: green;
    color: white;
    width: 40%;
    margin: 0px 10px;
}

.stock-details-sell-btn {
    background-color: red;
    color: white;
    width: 40%;
    margin: 0px 10px;
}