.order-data-section {
    /* border: 1px solid blue; */
}

.order-data-head-con {
    /* border: 1px solid red; */
    margin: 0px 0px 20px 0px;
}

.order-data-head-con h2 {
    text-align: left;
    font-size: 30px;
    font-family: var(---font02);
}

.order-data-section-card-main-body {
    border: 1px solid #c6c6c6;
    padding: 10px;
    border-radius: 10px;

}

.order-data-section-card-con {
    /* border: 1px solid gray; */
}

.order-data-section-card-head-name-con {
    padding-bottom: 10px;
    border-bottom: 1px solid #c6c6c6;
}

.order-data-section-card-head-name h2{
    text-align: left;
    font-family: var(---font01);
    font-size: 15px;
    margin: 15px 0px 0px 0px;
    font-weight: 600;
}

.order-data-section-card-head-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.order-data-section-card-enquiry-for-con {
    text-align: left;
}

.order-data-section-card-enquiry-for-con h3 {
    text-transform: capitalize;
    font-size: 14px;
}

.order-data-section-card-enquiry-for-con button {
    border: none;
    font-size: 12px;
    background-color: var(---colorG);
    padding: 4px 13px;
    border-radius: 4px;
    font-weight: 600;
}

.order-data-section-card-status {
    text-align: right;
}

.order-data-section-card-status h4 {
    text-transform: capitalize;
    font-size: 14px;
}

.order-data-section-card-status button {
    border: none;
    font-size: 12px;
    background-color: orange;
    padding: 4px 13px;
    border-radius: 4px;
    font-weight: 600;
}



.order-data-section-card-daetils-con {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    /* margin-top: 10px; */
}

.order-data-section-first-con {
    /* border: 1px solid blue; */
    width: 50%;
    padding: 10px;
}

.order-data-section-inside-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.order-data-section-inside-con h5 {
    font-family: var(---font01);
    font-size: 12px;
    margin: 0px;
}

.order-data-section-inside-con h6 {
    font-family: var(---font01);
    font-size: 13px;
    margin: 0px;
    font-weight: 600;
}

.fistborder {
    border-right: 1px solid #c6c6c6;
}