.about-section-1{
    border: 1px solid blue;
}

/* 1/ */

.home-about-section-con {
    padding: 10vh 0px;
}
.about-banner{
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),url("../Images/Banner/banner\ 02.jpg") !important;
    background-size: cover !important;
    background-position: center !important;
}

.about-us-one-left {
    position: relative;
    /* background: whitesmoke; */
    border: 10px solid #507b73;
    padding: 20px 0px 20px 0px;
    background: url(http://localhost:3001/static/media/backgroun%20theam.206c2be….jpeg);
    background-size: 100% 100%;
}

.about-us-one-left img:first-child {
    width: 60%;
}

.about-section-page-about-details-con {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: none;
}

.about-section-page-about-details {
    padding: 30px 30px 30px;
    border: 1px solid black;
    width: 100%;
    border-left: none;
}

.about-section-page-about-info {
    text-align: left;
}

.about-section-page-about-info h3 {
    font-weight: 300;
    color: #1b1b1b;
    letter-spacing: .05em;
    font-family: var(---font01);
    text-transform: uppercase;
    margin: 0px 0px 10px 0px;
}

.about-section-page-about-info p {
    font-family: var(---font01);
    font-weight: 300;
    font-size: 14px;
}

/* 2/ */

.About-Section-Mission-Main-con {
    padding: 10vh 0px;
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Images/Home/what-is-market-volatility.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.About-Section-Mission-con {
    /* border: 1px solid red; */
}

.About-Section-Deatils-con {
    text-align: left;
}

.About-Section-Deatils-con h2 {
    font-size: 89px;
    line-height: 1em;
    white-space: normal;
    margin: 0px 0px 20px 0px;
    color: whitesmoke;
}

.About-Section-Deatils-con h3 {
    font-family: var(---font01);
    margin: 0px 0px 20px 0px;
    color: white;
    font-size: 20px;
}

.About-Section-Deatils-con p {
    font-family: var(---font01);
    font-size: 14px;
    color: white;
}