:root {
    --marquee-padding: 0px;
    --marquee-padding-negative: calc(var(--marquee-padding) * -1);
}

body {
    margin: 0px;
}

.marquee {
    /* border: 2px solid blue; */
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    padding: 5px 0;
    background-color: black;
    filter: drop-shadow(rgb(218, 165, 32) 0px -7px 9px);
    display: flex;
}

.marquee .marquee_text {
    display: inline-block;
    animation: marquee 50s linear infinite;
}

.marquee .marquee_text ul {
    display: inline-flex;
    font-size: 16px;
    text-transform: uppercase;
    list-style-position: inside;
    text-decoration: none;
}

.marquee .marquee_text ul.marquee-content-primary {
    padding-left: 0px;
}

.marquee .marquee_text ul>li {
    padding-inline: 7px;
    display: flex;
    color: white;
    font-size: 14px;
}

.marquee .marquee_text ul>li h3 {
    color: #DAA520;
    margin: 0px 4px 0px 10px;
    font-size: 15px;
}

.marquee .marquee_text ul>li h4 {
    font-size: 15px;
    color: green;
    margin: 0px;
}

.marquee-content-secondary {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: var(--marquee-padding);
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(var(--marquee-padding-negative), 0, 0);
    }
}

.demo{
    border: 2px solid red;
}