/* second section  */

.stock-banner {
    background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Images/Banner/stock\ banner\ image.jpg") !important;
    background-size: cover !important;
    background-position: center !important;
}


.men-section-filter-con {
    /* border: 1px solid blue; */
    background-color: black;
}

.men-section-filert {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.men-section-main-filert {}

.men-section-main-filert-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-right: 1px solid white;
    border-left: 1px solid white;
}

.men-section-main-filert-details .form-select {
    color: white;
    background-color: black;
    border: none;
    font-family: var(---font01);
    font-size: 14px;
    text-transform: capitalize;
    width: fit-content;
}

.men-section-main-filert-details .form-select:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.men-section-main-filert-details2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    border-right: 1px solid white;
    border-left: 1px solid white;
}

.men-section-main-filert-details h2 {
    font-family: var(---font01);
    font-size: 12px;
    margin: 0px;
    text-transform: uppercase;
    color: white;
}

.men-section-main-filert-details svg {
    margin: 0px 0px 0px 7px;
    font-size: 12px;
    color: white;
}

.men-section-main-filert-details2 h2 {
    font-family: var(---font01);
    font-size: 12px;
    margin: 0px;
    text-transform: uppercase;
    color: white;
}

.men-section-main-filert-details2 svg {
    margin: 0px 0px 0px 7px;
    font-size: 15px;
    color: white;
}

.men-section-main-sort-by {}


/* ///////////////////////////////////////// */

.stocks-section-2-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.stocks-section-main-con {
    /* border: 1px solid red; */
}

/* search con  */
.stock-section-search-main-con {
    width: 100%;
}

.search-section-main-con {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-section-con {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.search-icons-con svg {
    font-size: 25px;
    color: white;
}

.search-bar-main-con {
    width: 100%;
}

.search-bar-main-con input {
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
    background-color: black;
    padding: 0px 20px;
    height: 40px;
    /* border: 1px solid blue; */
    color: white;
}

.search-cancel-con {
    font-size: 25px;
    color: white;
}

.men-section-main-filert-details .form-select option{
    padding: 5px 0px !important;
}