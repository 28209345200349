.Home-Section-2 {
    /* border: 1px solid blue; */
    background: whitesmoke;
    padding: 30px 0px;
}

.home-section-2-con {
    /* border: 1px solid red; */
}

.home-section-2-Image-con {
    /* border: 1px solid green; */
}

.home-section-2-image {
    /* border: 1px solid hotpink; */
    width: 80%;
    height: auto;
}

.home-section-2-image img {
    height: 100%;
    width: 100%;
}

.home-section-2-About-Details-con {
    /* border: 1px solid blue; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section-2about-details {
    /* border: 1px solid red; */
    text-align: left;
}

.home-section-2about-details h2 {
    font-family: var(---font01);
    font-size: 50px;
}

.home-section-2about-details h2 span {
    color: var(---colorG);
}

.home-section-2about-details p {
    font-family: var(---font01);
}