.Profiles-Section-1 {
    /* border: 1px solid red; */
    height: 40vh;
    background: whitesmoke;
    /* background: linear-gradient(90deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../Images/jute_banner.jpg"); */
    -webkit-background-size: cover;
}

.Profiles-Section-con {
    /* border: 1px solid blue; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 40vh;
}

.Profiles-Section-Text {
    margin: 0px 0px 0px 30px;
}

.Profiles-Section-NavPage {
    display: flex;
    align-items: center;
    font-family: var(---SmallFont);
}

.Profiles-Section-Text h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---MainHeadFont);
    font-weight: 500;
    font-size: 45px;
    letter-spacing: 1px;
    color: black;
}

.Profiles-Section-NavPage span {
    font-family: var(---Font1);
    color: black;
    cursor: pointer;
}

.Profiles-Section-NavPage span svg {
    font-size: 17px;
    margin: 0px 4px 5px 0px;
}

.Profiles-Section-NavPage h3 {}

.Profiles-Section-NavPage h3 svg {
    color: black;
}

.Profiles-Section-NavPage h2 {
    font-size: 16px;
    margin: 0px;
    font-family: var(---Font1);
    font-weight: normal;
    color: var(---SmallColor);
}


/* /////////////////////////////////////// Section 2 //////////////////////////////////////// */

.Profile-Section-2 {
    /* border: 1px solid red; */
    padding: 30px 0px;
}

.Profile-Section-Sidebar {
    /* border: 1px solid blue; */
    box-shadow: 0 30px 50px #052f281f;
}

.Profile-Section-Sidebar .nav-pills button {
    text-align: left !important;
    padding: 20px 20px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0989ff0f !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: black !important;
}

.nav-pills .nav-link {
    border-radius: 0px !important;
}


.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #821f4026 !important;
}

.Profile-Section-Sidebar .nav-pills .nav-link {
    color: var(---FontColor01);
}

.ant-modal-footer .ant-btn {
    background-color: var(---FontColor01) !important;
    color: white !important;
}